import type { FC, ReactElement, ReactNode } from "react";
import { useEffect } from "react";
import type { NextPage } from "next";
import type { AppProps } from "next/app";
import Head from "next/head";
import { SessionProvider } from "next-auth/react";
import { DefaultSeo } from "next-seo";
import type { EmotionCache } from "@emotion/cache";
import { DehydratedState } from "@tanstack/react-query";

import { createEmotionCache } from "@shared/lib//utils/create-emotion-cache";
import { AnalyticsTrackerByRouter } from "@/components/common/features/analytics-tracker";
import { ChannelTalkController } from "@/components/common/features/channel-talk";
// import { RefreshAccessTokenErrorObserver } from '@/components/common/features/refresh-access-token-error-observer'
import seo from "@/config/next-seo";
import ReactQueryProvider from "@/providers/react-query-provider";
import { StyleProvider } from "@/providers/style-provider";
import { Session } from "@/types/auth";

import "moment/locale/ko";
import dayjs from "dayjs";
import "dayjs/locale/ko";

dayjs.locale("ko");

export type NextPageWithLayout<P = object> = NextPage<P> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

export type EnhancedAppProps = AppProps<{
  session?: Session | null;
  dehydratedState?: DehydratedState;
}> & {
  Component: NextPageWithLayout;
  emotionCache: EmotionCache;
};

const clientSideEmotionCache = createEmotionCache();

const App: FC<EnhancedAppProps> = (props) => {
  const {
    Component,
    pageProps: {
      /**
       * getServerSideProps에서
       * next-auth의 getServerSession을 이용해서 session을 꺼낸 다음
       * props.session에 session을 넘김
       * 그렇게 하면 _app에서 pageProps.session을 가져올 수 있음
       * pageProps에서 session을 제외한 나머지를 페이지 컴포넌트로 전달하고
       * session은 SessionProvider로 전달하여
       * 1. session은 useSession을 통해서만 사용할 수 있도록 하고
       * 2. useSession을 통해서 가져오는 session의 최초 값을 설정함
       *    (클라이언트 사이드에서 세션을 가져오는 로딩을 없앨 수 있음)
       */
      session,
      dehydratedState,
      ...pageProps
    },
    emotionCache = clientSideEmotionCache,
  } = props;

  const getLayout = Component?.getLayout ?? ((page) => page);

  /**
   * 오른쪽 클릭 방지
   */
  useEffect(() => {
    const preventContext = (e: MouseEvent) => e.preventDefault();
    document.addEventListener("contextmenu", preventContext);

    return () => document.removeEventListener("contextmenu", preventContext);
  }, []);

  return (
    <>
      <DefaultSeo {...seo} />

      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1, shrink-to-fit=no, viewport-fit=cover"
        />
      </Head>

      <SessionProvider
        session={session}
        //30분
        // refetchInterval={60 * 30}
        // refetchOnWindowFocus={true}
      >
        <ReactQueryProvider dehydratedState={dehydratedState}>
          <StyleProvider emotionCache={emotionCache}>
            {/* <RefreshAccessTokenErrorObserver /> */}
            <AnalyticsTrackerByRouter />
            <ChannelTalkController />
            {getLayout(<Component {...pageProps} />)}
          </StyleProvider>
        </ReactQueryProvider>
      </SessionProvider>
    </>
  );
};

export default App;

import { css } from "@emotion/react";

import { theme } from "./theme";

export default css`
  //Reset CSS
  * {
    box-sizing: border-box;
  }

  body {
    margin: 0;
    padding: 0;
    background-color: #ffffff !important;

    &.body-lock {
      overflow: hidden !important;
    }
  }

  article,
  aside,
  details,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  main,
  menu,
  nav,
  section,
  summary {
    display: block;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    li {
      padding: 0;
      margin: 0;
    }
  }

  audio,
  canvas,
  progress,
  video {
    display: inline-block;
    vertical-align: baseline;
  }

  audio:not([controls]) {
    display: none;
    height: 0;
  }

  [hidden],
  template {
    display: none;
  }

  a {
    cursor: pointer;
    background-color: transparent;
    color: inherit;
    text-decoration: none;
  }

  a:active,
  a:hover {
    outline: 0;
  }

  a:visited {
    color: inherit;
  }

  abbr[title] {
    border-bottom: 1px dotted;
  }

  b,
  strong {
    font-weight: bold;
  }

  dfn {
    font-style: italic;
  }

  h1 {
    font-size: 2em;
    margin: 0.67em 0;
  }

  mark {
    background: #ff0;
    color: #000;
  }

  small {
    font-size: 80%;
  }

  sub,
  sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
  }

  sup {
    top: -0.5em;
  }

  sub {
    bottom: -0.25em;
  }

  img {
    border: 0;
  }

  svg:not(:root) {
    overflow: hidden;
  }

  figure {
    margin: 1em 40px;
  }

  hr {
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    height: 0;
  }

  pre {
    overflow: auto;
  }

  code,
  kbd,
  pre,
  samp {
    font-family: monospace, monospace;
    font-size: 1em;
  }

  button,
  input,
  optgroup,
  select,
  textarea {
    color: inherit;
    background-color: transparent;
    font: inherit;
    margin: 0;
  }

  button {
    cursor: pointer;
    overflow: visible;
  }

  button,
  select {
    text-transform: none;
  }

  button,
  html input[type="button"],
  input[type="reset"],
  input[type="submit"] {
    -webkit-appearance: button;
    cursor: pointer;
  }

  button[disabled],
  html input[disabled] {
    cursor: default;
  }

  button::-moz-focus-inner,
  input::-moz-focus-inner {
    border: 0;
    padding: 0;
  }

  input {
    line-height: normal;
  }

  input[type="checkbox"],
  input[type="radio"] {
    box-sizing: border-box;
    padding: 0;
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    height: auto;
  }

  input[type="search"] {
    -webkit-appearance: textfield;
    -moz-box-sizing: content-box;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
  }

  input[type="search"]::-webkit-search-cancel-button,
  input[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
  }

  input:focus {
    outline: none !important;
  }

  fieldset {
    border: 1px solid #c0c0c0;
    margin: 0 2px;
    padding: 0.35em 0.625em 0.75em;
  }

  legend {
    border: 0;
    padding: 0;
  }

  textarea {
    overflow: auto;
  }

  optgroup {
    font-weight: bold;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  td,
  th {
    padding: 0;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  a {
    text-decoration: none;
    text-underline-style: none;
  }

  input {
    outline: none;
    :focus {
      outline: none;
    }
  }

  button {
    margin: 0;
    padding: 0;
    outline: none;
    border: none;
    background: none;
    :focus {
      outline: none;
    }
  }

  #__next {
    position: relative;
  }

  .tablet,
  .tablet-sm,
  .mobile {
    display: none !important;
  }

  .pc {
    display: flex !important;
  }

  ${theme.media.onlyTablet} {
    .pc,
    .tablet-sm,
    .mobile {
      display: none !important;
    }

    .tablet {
      display: flex !important;
    }
  }

  ${theme.media.tabletSm} {
    ::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  ${theme.media.onlyTabletSm} {
    .pc,
    .tablet,
    .mobile {
      display: none !important;
    }

    .tablet-sm {
      display: flex !important;
    }
  }

  ${theme.media.onlyMobile} {
    .pc,
    .tablet,
    .tablet-sm {
      display: none !important;
    }

    .mobile {
      display: flex !important;
    }
  }

  //Animation
  @keyframes rotate_image {
    100% {
      transform: rotate(360deg);
    }
  }

  //Module
  .bar {
    background-color: #ffff !important;
    height: 3px !important;
  }
`;
